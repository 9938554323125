@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes pan {
  0% {
    background-position: 0% center;
  }
  100% {
    background-position: 100% center;
  }
}

.background-pan {
  @apply relative flex items-center justify-center min-h-screen w-full;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('roman_empire.png');
  animation: pan 40s infinite linear;
}

.text-white {
  color: #ffffff;
  font-size: large;
}

.spacing-p-b {
  padding-bottom: 2rem;
}

.button {
  /* px-4 py-2 bg-black text-white rounded */
  border-radius: 4px;
  border-color: #ffffff;
  border-width: 2px;
  border-style:solid;
  padding:4px 8px;
}